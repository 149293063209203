.jumping-number-input {
  height: 60px;
  font-weight: 700;
}

.jumping-num-input2 {
  position: absolute;
  bottom: 11rem;
}

.custom-btn-position {
  position: absolute;
  bottom: 2rem;
}

.common-button-2 {
  letter-spacing: 0.18rem;
  background-color: #193d6d;
  color: #fff;
  width: 110%;
}

.next-button {
  padding-right: 0.5rem;
}

.common-button-2:hover {
  background-color: #fff;
  border: 2px solid #193d6d;
  color: #000;
}

.sum-check {
  height: 80vh;
}

.jumping-number-main {
  height: calc(16vh);
}

.remaining-time {
  display: flex;
  font-size: calc(4vw);
}

.inputBox {
  bottom: 60%;
}

.un-answered,
.right,
.wrong {
  margin: 1px;
  color: white;
  width: 1.8vw;
  height: 1.8vw;
  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 1.25rem;
  align-items: center;
  flex-shrink: 0;
  line-height: 1;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
}

.right {
  background: green;
}

.wrong {
  background: rgb(224, 46, 46);
}

.un-answered {
  margin: 3px;
  border: 1px solid #000;
}

.start {
  margin: 0px 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 20px;
  color: rgb(75, 73, 73);
  text-align: center;
  border: 1px solid black;
}

.answer-result {
  display: grid;
  grid-template-columns: repeat(10, 3vw);
}

.question {
  font-size: calc(1.5vw + 1.5vh);
}

.jumping-num-container {
  min-height: calc(25vh);
}

@media (min-width: 321px) and (max-width: 489px) {
  .jumping-num-input2 {
    bottom: 4rem !important;
    padding-left: 24px;
  }

  .common-button-2 {
    font-size: 4vw;
    padding: 9px !important;
    width: 111px;
    left: -1px;
  }

  .jumping-number-input {
    bottom: 45px;
    right: 15px;
    width: 104%;
    font-size: 1.4rem;
    height: 2.7rem;
    padding: 0.3rem 0.4rem 0 2.3rem !important;
  }
}
@media screen and (min-width: 490px) and (max-width: 700px) {
  .common-button-2 {
    left: 107px;
    bottom: 40px !important;
  }
  .jumping-number-input {
    right: 15px;
    width: 104%;
    font-size: 1.4rem;
    height: 2.1rem;
    padding: 1.3rem 0.4rem 0 2.3rem !important;
  }
}
@media screen and (min-width: 701px) and (max-width: 767px) {
  .answer-result {
    display: none;
  }
  .jumping-num-container {
    min-height: calc(33vh) !important;
  }

  .question {
    line-height: 1.5 !important;
  }

  .jumping-number-input {
    right: 15px;
    width: 104%;
    font-size: 1.4rem;
    height: 2.1rem;
    padding: 0.3rem 0.4rem 0 2.3rem !important;
  }

  .common-button-2 {
    left: 124px;
    bottom: 24px !important;
  }

  .remaining-time {
    padding-bottom: 0px;
    display: flex;
  }
  .custom-btn-position {
    bottom: 1rem;
  }
  .jumping-num-input2 {
    bottom: 5rem;
  }
}

@media (min-width: 768px) and (max-width: 899px) and (orientation: landscape) {
  .number-input {
    margin-right: 150px;
  }
  .jumping-num-input2 {
    bottom: 4rem !important;
  }
  .custom-btn-position {
    bottom: 1rem !important;
  }
  .remaining-time,
  .answer-result,
  .question {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 670px) and (max-width: 768px) {
  .jumping-number-input {
    width: 100.5%;
    height: 36px;
    padding: 0.3rem 0.4rem 0 2.3rem !important;
  }
  .common-button-2 {
  }
}

@media (min-width: 500px) and (max-width: 991px) and (orientation: landscape) {
  .jumping-num-input2 {
    bottom: 5rem !important;
  }
  .custom-btn-position {
    bottom: 1rem !important;
  }
  .remaining-time,
  .answer-result,
  .question {
    margin-top: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .jumping-num-input2 {
    bottom: 8rem;
  }
  .jumping-number-input {
    width: 100.5%;
    height: 60px;
    padding: 0.3rem 0.4rem 0 2.3rem !important;
  }
  .common-button-2 {
    top: -45% !important;
    width: 99.3%;
    margin-left: 18px;
  }
}
@media (min-width: 1024px) and (max-width: 1439px) {
  .jumping-num-input2 {
    bottom: 8rem;
    padding-left: 18px !important;
  }
  .jumping-number-input {
    width: 100.5%;
    height: 60px;
    padding: 0.3rem 0.4rem 0 2.3rem !important;
  }
  .inputBox {
    bottom: 111%;
  }
}
@media (min-width: 1440px) and (max-width: 1799px) {
  .jumping-number-input {
    width: 96.5%;
    height: 56px;
    padding: 0.3rem 0.4rem 0 2.3rem !important;
  }
  .jumping-num-input2 {
    bottom: 8rem;
  }
}
@media screen and (min-width: 1800px) {
  .jumping-number-input {
    width: 100%;
    font-weight: 700;
    height: 70px;
    padding: 0.3rem 0.4rem 0 2.3rem !important;
  }
  .common-button-2 {
    width: 95%;
  }
}
