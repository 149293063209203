#root {
  overflow-x: hidden;
  background-color: white;
}
.pl-board-6 {
  padding-left: 2rem;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-h1 {
  font-size: 50px;
}

.result-popup {
  margin-top: 20px !important;
}

.instr-min-height {
  min-height: calc(84.2vh);
}

.dice-font-size {
  font-size: 2.8vw;
}

.custom-container {
  width: 100%;
}

.custom-btn {
  background-color: #153e6e;
  border-color: #153e6e;
  color: #fff;
}

.result-maindiv {
  background-color: #f4f8f8;
  width: calc(25vw);
  height: calc(75vh);
}

.confetti {
  width: calc(30vw);
  height: calc(60vh);
}
.custom-btn:hover {
  background-color: #fff;
  color: #000;
}
.custom-bg-color {
  background-color: #133d6d;
}

.modal-dialog {
  width: 460px;
}

.modal-header {
  border-bottom: 0 none;
}

.list {
  list-style: none;
}

.list li:before {
  content: '👉';
  margin: 0 16px 0 0;
}

.footer-font {
  font-size: 20px;
  letter-spacing: 0.1rem;
}

.custom-letter-spacing {
  letter-spacing: 0.18rem;
}
.custom-letter-spacing1 {
  line-height: 1.2;
  margin-top: 18%;
}

.common-button {
  letter-spacing: 0.18rem;
  background-color: #193d6d;
  color: #fff;
}

.common-button:hover {
  background-color: #fff;
  border: 1px solid #193d6d;
}

.common-button-1 {
  letter-spacing: 0.18rem;
  background-color: #193d6d;
  color: #fff;
  width: 91%;
  position: absolute;
  bottom: 3rem;
}

.timer-font-size {
  font-size: 3.5vw;
}

.common-button-1:hover {
  background-color: #fff;
  border: 2px solid #193d6d;
  color: #000;
}

.main-page-height {
  display: inline-block;
  padding: 0 20px;
  border-bottom: 0;
}

.modal {
  overflow: hidden !important;
}

.math-inst-font-size1 {
  font-size: 2.75rem !important;
  margin-bottom: -1.5px;
}

.font-size-1 {
  font-size: 24px !important;
}

.custom-font-size {
  font-size: 2vw;
}

.timer-font-size2 {
  font-size: 3.5vw;
  margin-bottom: -1.5rem;
}

.custom-font-size-1 {
  right: calc(0.5vw);
  font-size: 3.4vw;
  margin-top: calc(-0.7vw) !important;
}

.gameHeading {
  font-size: calc(5vh + 2vw);
}
/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 300px to 480px
*/
@media (min-width: 300px) and (max-width: 480px) {
  .custom-h1 {
    font-size: 28px;
  }

  .animate-charcter {
    font-size: 78px !important;
  }

  .result-popup {
    margin-top: 2px !important;
  }

  .custom-xs-padding {
    padding: 0 0.5rem;
  }
  .custom-font-size-1 {
    font-size: 5.4vw;
    margin-top: calc(1.8vw) !important;
  }
  .custom-font-size {
    font-size: 4vw;
    padding: 0 !important;
    margin-top: 6px;
  }
  .common-button-1 {
    width: 80% !important;
    bottom: 0.25rem;
  }
  .mt-lanscape-md-0 {
    margin-top: 0.25rem !important;
  }
  .pl-board-6 {
    padding-left: 1.9rem;
  }

  .modal-dialog {
    width: auto;
  }
  .footer-font {
    font-size: calc(50% + 1.2vw);
    letter-spacing: 0.1rem;
  }

  .math-inst-font-size2 {
    font-size: 15px !important;
  }

  .math-inst-font-size1 {
    font-size: 1.55rem !important;
    margin-bottom: -1.5px;
  }
}

@media (min-width: 567px) and (max-width: 568px) {
  .common-button-1 {
    bottom: 0.25rem !important;
  }
  .mt-lanscape-md-0 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .pl-board-6 {
    padding-left: 1.9rem;
  }

  .result-popup {
    margin-top: 2px !important;
  }

  .common-button-1 {
    width: 91% !important;
    bottom: 1rem;
  }
  .custom-font-size-1 {
    right: calc(0.5vw);
    font-size: 5.4vw;
    margin-top: calc(-0.5vw);
  }
}
/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 991px) and (orientation: landscape) {
  .main-page-height {
    margin-bottom: 39px;
  }
  .dice-font-size {
    margin-bottom: -10px;
  }
  .timer-font-size {
    margin-top: -32px;
  }
  .custom-margin-userinput {
    margin-top: 0.7rem !important;
  }

  .die-conatiner {
    margin-top: -10px !important;
    margin-bottom: -50px;
  }

  .pl-board-6 {
    padding-left: 1.9rem;
  }

  .common-button-1 {
    width: 94% !important;
    margin-bottom: -45px !important;
  }
}

@media (min-width: 812px) and (max-width: 823px) and (orientation: landscape) {
  .mt-lanscape-md-0,
  .die-conatiner {
    margin-top: 0.25rem !important;
  }

  .common-button-1 {
    bottom: 0.25rem !important;
  }

  .dice-container {
    margin-top: 1.5rem !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .math-inst-font-size2 {
    font-size: 15px !important;
  }

  .custom-margin-userinput {
    margin-top: 0.7rem !important;
  }
  .common-button-1 {
    width: 93%;
  }
}

@media (min-width: 1000px) and (max-width: 1199px) {
  .math-inst-font-size2 {
    font-size: 1rem !important;
  }

  .animate-charcter {
    font-size: 110px !important;
  }

  .dice-font-size {
    font-size: 2.8vw;
    margin-top: -2rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 1.5rem !important;
  }

  .math-inst-font-size2 {
    font-size: 15px !important;
  }
  .mt-lanscape-lg-1 {
    margin-top: 2rem !important;
  }

  .custom-font-size-1 {
    right: calc(0.9vw);
    margin-top: calc(-0.3vw) !important;
  }
  .mathGameImage {
    /* margin-top: 60% !important; */
    height: 40% !important;
  }
}

@media (min-width: 1281px) {
  .main-page-height {
    height: 10rem;
    width: 85rem;
  }
}
@media (min-width: 1200px) and (max-width: 1300px) {
  .mathGameImage {
    /* margin-top: 40% !important; */
    height: 60% !important;
  }
}

@media (min-width: 1301px) and (max-width: 1399px) {
  .mathGameImage {
    /* margin-top: 30% !important; */
    height: 60% !important;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .math-inst-font-size2 {
    font-size: 15px !important;
  }

  .common-button {
    margin-right: -25px;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 0.4rem !important;
  }
}

@media (min-width: 1400px) and (max-width: 1499px) {
  .math-inst-font-size2 {
    font-size: 15px !important;
  }

  .dice-font-size {
    font-size: 2.8vw;
    margin-top: -2rem !important;
    margin-bottom: -3rem !important;
  }
  .custom-font-size-1 {
    margin-top: calc(-0.5vw) !important;
  }
  .mathGameImage {
    /* margin-top: 30% !important; */
    height: 60% !important;
  }
  .custom-drop {
    font-size: 2.5rem !important;
  }
}

@media (min-width: 1500px) and (max-width: 1803px) {
  .math-inst-font-size2 {
    font-size: 16px !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 2.5rem !important;
  }
  .dice-font-size {
    font-size: 2.8vw;
    margin-top: -2rem !important;
    margin-bottom: -3rem !important;
  }
  .custom-drop {
    font-size: 2.5rem !important;
  }
}

@media (min-width: 1804px) and (max-width: 2561px) {
  .mathGameImage {
    height: 30rem !important;
  }
  .instr-min-height {
    min-height: calc(88.2vh);
  }
  .common-button-1 {
    width: 91% !important;
    margin-bottom: -18px;
  }
  .custom-font-size-1 {
    margin-top: calc(-0.7vw) !important;
  }

  .math-inst-font-size2 {
    font-size: 18px !important;
  }

  .pt-xl-6 {
    padding-top: 4.9rem !important;
  }

  .extramargin {
    margin-top: 75px !important;
  }
  .custom-drop {
    font-size: 2.5rem !important;
  }
}

/* maths degisn css  */
.animate-charcter {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: 150px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.btn-primary {
  color: #fff;
  background-color: #193d6d;
}
.btn-primary:hover {
  color: #000;
  background-color: #fff;
  border-color: #193d6d;
}

.btn-info {
  color: #fff;
}
.btn-info:hover {
  color: #000;
  background-color: #fff;
  border-color: #1ba2b8;
}

.sureMessage {
  color: #000;
  font-size: 1.7rem;
  font-weight: bold;
}

.sureMessage1 {
  font-size: 1.3rem;
}

.img-fluid,
.custom-select:hover {
  cursor: pointer;
}

.LandingpageImg {
  width: 100%;
  height: 100%;
  position: absolute;
}
.box-design {
  position: relative;
  display: flex;
}

.boardFontStyle {
  font-family: 'Fredericka the Great', cursive;
}

.modal-lg {
  width: 100% !important;
}

/* Dice click text design */

.title-word-1 {
  animation: color-animation 1s linear infinite;
  --color-1: #1721c1;
  --color-2: #f33e09;
}

@keyframes color-animation {
  0% {
    color: var(--color-1);
  }
  32% {
    color: var(--color-1);
  }
  33% {
    color: var(--color-2);
  }
  65% {
    color: var(--color-2);
  }
  66% {
    color: var(--color-2);
  }
  99% {
    color: var(--color-2);
  }
  100% {
    color: var(--color-1);
  }
}
