#main-board {
  /* display: grid; */
  grid-template-columns: repeat(6, 8vw);
}

::-webkit-input-placeholder {
  text-align: center;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ques-ans-input {
  padding-left: 3.5rem !important;
}

.jumping-num-board {
  background-color: white;
  min-height: calc(84.2vh);
}

.box-design {
  margin-left: 45px !important;
  border-radius: 0.5rem;
  height: calc(12vh);
  width: calc(6.5vw);
  border: 1px solid #fff;
  justify-content: center;
  font-size: calc(1.3vw + 2vh);
  display: flex;
  justify-content: center;
  align-items: center !important;
  font-family: 'Fredericka the Great', cursive;
}

.numbercolor {
  color: #000;
  background-color: red;
}

.green {
  color: #000;
  background-color: #2cad0c;
}

.red {
  background-color: #d84949;
}

.title {
  color: #183f71;
  font-size: 30px;
  font-weight: bold;
}

.box-design:hover {
  box-shadow: inset 0px 0px 0px 8px #fff;
  font-size: calc(1.3vw + 2vh);
  color: #26445e;
  cursor: pointer;
}
@media (min-width: 300px) and (max-width: 380px) {
  .box-design {
    margin-left: 16px !important;
    height: calc(12vh);
    width: 31px;
    margin-right: -15px;
  }
}
@media (min-width: 380px) and (max-width: 500px) {
  .box-design {
    margin-left: 17px !important;
    height: calc(12vh);
    width: 34px;
    margin-right: -15px;
  }

  .remaining-time {
    font-size: calc(2.8vw) !important;
  }
}

@media (min-width: 510px) and (max-width: 650px) {
  .box-design {
    width: 45px !important;
  }
}

@media (min-width: 510px) and (max-width: 850px) {
  .ques-ans-input {
    padding-left: 2.5rem !important;
  }

  .mt-md-6 {
    margin-top: 0px !important;
  }

  .box-design {
    margin-left: 21px !important;
    height: calc(12vh);
    width: 58px;
    margin-right: -10px;
  }

  .remaining-time {
    font-size: calc(3.8vw) !important;
  }
}

@media (min-width: 768px) and (max-width: 1440px) {
  .ques-ans-input {
    padding-left: 3rem !important;
  }
  .common-button-2 {
    margin-bottom: -23px;
    height: 58px;
  }
}
@media (min-width: 1000px) and (max-width: 1200px) {
  .box-design {
    margin-bottom: 14px;
    height: calc(10vh);
    width: calc(7.5vw);
    margin-left: 18px !important;
  }
  .remaining-time {
    font-size: calc(1.3vw + 3.5vh) !important;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .box-design {
    height: calc(10.5vh);
    width: calc(5.8vw);
    margin-bottom: 12px;
  }
}

@media (min-width: 1400px) and (max-width: 1700px) {
  .box-design {
    height: calc(9.3vh);
    width: calc(6vw);
    margin-bottom: 20px;
  }
}

@media (min-width: 1803px) and (max-width: 2299px) {
  .mt-xl-6 {
    margin-top: 2.5rem !important;
  }

  .box-design {
    height: calc(10vh);
    width: calc(6.1vw);
    margin: 12px;
  }

  .pl-xl-6 {
    padding-left: 2.5rem !important;
    padding-top: 3rem !important;
  }
  .jumping-num-board {
    min-height: calc(88.2vh);
  }
}

@media (min-width: 2300px) {
  .mt-xl-6 {
    margin-top: 7rem !important;
  }

  .pl-xl-6 {
    padding-left: 3.5rem !important;
    padding-top: 4rem !important;
  }
  .jumping-num-board {
    background-color: white;
    min-height: calc(88.2vh);
  }
}

.box-design.numbercolor {
  background-color: #193d6d;
  color: white;
}
