@charset "UTF-8";
:root {
  --star-size:calc(4.5vw);
  /* --star-height:calc(120vh); */
  --star-color: #fff;
  --star-background: #fc0;
}
.Stars {
  --percent: calc(var(--rating) / 15 * 100%);
  display: inline-block;
  font-size: var(--star-size);
  font-family: Times;
  line-height: 1;
}
.Stars::before {
  content: '★★★★★';
  letter-spacing: 3px;
  background: linear-gradient(
    90deg,
    var(--star-background) var(--percent),
    var(--star-color) var(--percent)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
body {
  background: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

