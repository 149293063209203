.custom-drop {
  position: fixed;
  top: -1vh;
  font-size: 1.5rem;
  transform: translateY(0);
  animation: fall 3s linear forwards;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@keyframes fall {
  to {
    transform: translateY(101vh);
  }
}

.custom-modal-toggle {
  width: 3rem;
  height: 3rem;
  background-size: cover;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  left: 170rem;
  border-radius: 50%;
  transition: transform 0.1s ease;
}

.custom-modal-toggle:hover {
  cursor: pointer;
  transform: scale(1.2);
}

.emojis-now {
  font-family: 'Poppins', sans-serif;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
  background-color: #492e91;
  padding: 1rem;
  color: #f3f3f3;
  transition: transform 0.1s ease;
  border-radius: 0.5rem 0.5rem 0 0;
}

.emojis-now.active {
  transform: translate(-50%, 0);
}
